import * as React from "react";
import Layout from "../../components/layout"
import Seo from "../../components/seo";
import Subscribe from "../../components/subscribe";
import CustomTooltipbtn from "../../components/tooltipbutton";
import { StaticImage } from "gatsby-plugin-image";

const facultyhostPage = () => (
  <Layout siteTitle={"Become a Mentor"} >
    <main>
        <div className="relative px-6 lg:px-8">
          <div className="mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
                <h1 className="text-4xl font-bold tracking-tight text-center sm:text-6xl">
                Become a Mentor
                </h1>
                <div className="position: relative z-40">
                <p className="mt-6 text-xl leading-8 text-gray-600 font-light text-center">
                WiN’s mission is to build a diverse community of leaders in neuroscience and neurology. We believe that mentorship is a key component to ensure our young women “WiNterns” build the confidence and leadership skills necessary to realize their career goals.
                </p>
                </div>
                <div className="mt-8 flex gap-x-4 sm:justify-center">
                  {/* <CustomTooltipbtn
                    zIndex="z-30" 
                    tooltipTitle="Applications open shortly. Please check back soon!" 
                    buttonClass="z-30 inline-block rounded-lg bg-indigo-600 px-4 py-1.5 font-semibold text-sm sm:text-base sm:leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700" 
                    buttonContent="Apply to be a Research or Clinical Host for Summer 2024" 
                    showArrow={true}
                    link="https://form.jotform.com/223548308925058" 
                  /> */}
                  <a
                    href="#learn-more"
                    className="z-30 inline-block rounded-lg px-4 py-1.5 sm:text-base font-semibold sm:leading-7 text-sm text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                  >
                    Learn More{' '}
                    <span className="text-gray-400" aria-hidden="true">
                      &rarr;
                    </span>
                  </a>
              </div>
              <div className="z-20 absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <svg
                  className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                  viewBox="0 0 1155 678"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                  <defs>
                    <linearGradient
                      id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                      x1="1155.49"
                      x2="-78.208"
                      y1=".177"
                      y2="474.645"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9089FC" />
                      <stop offset={1} stopColor="#FF80B5" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
          </div>
        </div>
    </main>

    <div className="relative bg-white py-16">
      <div className="absolute inset-x-0 top-0 hidden h-1/2 lg:block" aria-hidden="true" />
      <div className="mx-auto max-w-7xl bg-indigo-600 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
            <div className="absolute inset-x-0 h-1/2 lg:hidden" aria-hidden="true" />
            <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:p-0">
              <div className="!aspect-w-10 !aspect-h-6 sm:!aspect-w-2 sm:!aspect-h-1 lg:!aspect-w-1">
                <StaticImage
                  className="lg:rounded-3xl shadow-2xl"
                  src="../../images/MentorBanner1.jpg"
                  alt=""
                  objectFit={"cover"}
                  objectPosition={"center"}
                />
              </div>
            </div>
          </div>

          <div className="relative bg-indigo-600 lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
            <div className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block" aria-hidden="true"></div>
            <div className="relative mx-auto max-w-md space-y-6 py-12 px-6 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
              <h2 className="text-3xl font-bold tracking-tight text-indigo-700" id="join-heading">
                About the Summer Internship
              </h2>
              <p className="text-lg text-white">
                WiN awards students an 10-week internship at UT Neuroscience or Dell Medical Neurology in a research or clinical setting. Students gain significant knowledge about the fields of neuroscience and neurology, hands-on experience, and personal and professional growth. The internship includes a $5,000 summer stipend so the student can supplement earnings while spending the summer learning.
                <br /><br />
                Research and Clinical Hosts serve as mentors by including the intern as a member of their lab - guiding them through specific projects, teaching critical skills, allowing them to shadow your work, and more.
              </p>
              <div className="inline-block">
              <CustomTooltipbtn 
                zIndex='z-40'
                tooltipTitle="Applications open shortly. Please check back soon!" 
                buttonClass="inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white ring-1 ring-white/10 hover:text-indigo-700 hover:ring-indigo-700" 
                buttonContent="Apply to be a mentor" 
                showArrow={true}
                link="https://form.jotform.com/223548308925058" 
              />
              </div>
            </div>
          </div>


          
        </div>
      </div>
    </div>

    <section className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl px-6 pt-20 pb-12 lg:px-8 lg:py-20">
        <svg
          className="absolute top-full left-0 translate-x-80 -translate-y-24 transform lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
        </svg>

        <svg
          className="absolute right-full top-1/2 hidden translate-x-1/2 -translate-y-1/2 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="56409614-3d62-4985-9a10-7ca758a8f4f0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
        </svg>

        <div className="relative lg:flex lg:items-center">
          <div className="hidden lg:block lg:flex-shrink-0">
            <StaticImage
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src="../../images/InternMaria.jpg"
              alt=""
            />
          </div>

          <div className="relative lg:ml-10">
            <svg
              className="absolute top-0 left-0 h-36 w-36 -translate-x-8 -translate-y-24 transform text-indigo-200 opacity-50"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
              aria-hidden="true"
            >
              <path
                strokeWidth={2}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <div className="text-2xl font-medium leading-9 text-gray-900">
                <p>
                Kaelin was the most wonderful mentor on earth. My day consisted of collecting data by counting worms, setting up plates, helping around the lab, entering data, attending lab meetings, and shadowing Kaelin. With her guidance, the internship pushed me to learn and grow as a researcher. 
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    <img
                      className="h-12 w-12 rounded-full"
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="text-base font-medium text-gray-900">Maria</div>
                    <div className="text-base font-medium text-indigo-600">WiNtern, Ann Richards School for Young Women Leaders, The University of Texas at Austin</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <div id='learn-more' className="relative overflow-hidden bg-white py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-indigo-600">Student WiNterns</span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              A Day in the Life
            </span>
          </h1>
        </div>
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          <p>
          Each WiNtern is assigned to a mentor and their lab. On a daily basis, she shadows and works hands-on with her mentor and lab team to understand lab protocol, complete research tasks, and learn how the Scientific Method furthers research in that lab and in the fields of neuroscience and neurology.
          <br /><br />
          Throughout the internship, interns come together for workshops and speaker sessions to broaden knowledge in these fields and to enhance personal and professional development. 
          <br /><br />
          Midway through the internship, the interns share experiences and lab research through presentations and lab visits. At the internship’s completion, each intern makes a final Presentation of Learning
          <br /><br />
          <strong>Requirements</strong> 
          </p>
          <ul>
            <li>All interns are 18 years old or older</li>
            <li>All interns are undergraduates, ranging from incoming freshman to rising seniors</li>
            <li>Interns must work at least 30 hours per week, which includes:</li>
              <ul>
            <li>Virtual Speaker Series – 4-1/2 hours per week</li>
            <li>WiNtern group meetups – 1-1/2 hours per week</li>
            <li>Lab specific work – 24 hours per week</li>
              </ul>
            <li>Hosts must schedule and attend an introductory meeting with their intern at least 1 week before the June 3 start date</li>
            <li>Hosts are expected to attend their intern’s midterm and final presentations during the fourth and eighth weeks of the internship. Dates and times will be assigned during the first week of the internship, and we will happily work with your schedule.</li>
            <li>Faculty, interns and WiN staff are asked to sign an Expectations Contract to ensure expectations are aligned among all parties</li> 
          </ul>
          {/* <CustomTooltipbtn
            zIndex="z-30" 
            tooltipTitle="Applications open shortly. Please check back soon!" 
            buttonClass="z-30 inline-block rounded-lg bg-indigo-600 px-4 py-1.5 sm:text-base text-sm font-semibold sm:leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700" 
            buttonContent="Apply to be a Research or Clinical Host for Summer 2024" 
            showArrow={true}
            link="https://form.jotform.com/223548308925058" 
          />        
          <br /> */}
          <p>
          <strong>Other Opportunities</strong><br /><br />WiN is constantly evolving and building additional resources for our students. If you are interested in being a mentor aside from our Summer Internship, let us know and we will keep you in the loop as we develop new programs.
          </p>
          <div className="mt-8">
          <CustomTooltipbtn
            zIndex="z-30" 
            tooltipTitle="Applications open shortly. Please check back soon!" 
            buttonClass="z-30 inline-block rounded-lg bg-indigo-600 px-4 py-1.5 sm:text-base font-semibold sm:leading-7 text-sm text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700" 
            buttonContent="More Mentorship Opportunities with WiN" 
            showArrow={true}
            link="https://form.jotform.com/233304951087153" 
          />
          </div>
        </div>
      </div>
    </div>

    <div className="relative sm:my-12 bg-gray-900">
      <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/MentorBanner2.jpg"
          alt=""
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="pr-6 pl-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <h2 className="text-base font-semibold leading-7 text-indigo-700">Next steps</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white">To Apply</p>
          <p className="mt-6 text-base leading-7 text-gray-300">
          WiN matches mentors with interns based on the information you provide. While we can’t guarantee that everything you request in an intern will be provided, the more specific you can be, the better match we can make. We aim for the internship to be a positive and enriching experience for the mentor as well as the intern. 
          </p>
          <div className="mt-8">
            <CustomTooltipbtn
              zIndex="z-40" 
              tooltipTitle="Applications open shortly. Please check back soon!" 
              buttonClass="inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white ring-1 ring-white/10 hover:text-indigo-700 hover:ring-indigo-700" 
              buttonContent="Apply to be a mentor" 
              showArrow={true}
              link="https://form.jotform.com/223548308925058" 
            />
          </div>
        </div>
      </div>
    </div>

    {/* email subscribe */}
    <Subscribe />

  </Layout>

)

export const Head = () => <Seo title="Become a Mentor" />

export default facultyhostPage